
.demo-ruleForm {
    /*margin: 0 20px;*/
    ::v-deep .el-form-item__label {
        font-size: 16px;
        color: #333;
    }
}
.big-title-blk {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.industry_data {
  display: none;
}
.big-title {
    margin: 40px 0;
    font-size: 16px;
    padding-left: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    &:before {
        content: '';
        width: 4px;
        height: 16px;
        background: #2DC079;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 2px;
    }
}
.form-item {
    font-size: 16px;
    .title-item-first {
        width: 120px;
        height: 40px;
        line-height: 40px;
        text-align: right;
        margin-right: 16px;
    }
    .title-right {
        width: 1%;
        flex: 1;
        display: flex;
    }
    .title-item {
        width: 140px;
        line-height: 40px;
        margin-right: 50px;
    }
}
.align-center {
    text-align: center;
}
.number-input1 {
    width: 100%;
}
::v-deep .number-input1 {
    &.isError {
        .el-input__inner {
            border-color: #FD4446;
        }
    }
}
